import React from 'react'
import '../styles/Panel.css'
import axios from 'axios'
import swal from '@sweetalert/with-react'

let ratingValueOfQuality = 0;
let ratingValueOfPerformance = 0;
let ratingValueOfTiming = 0;
let message = "";

export default function Panel(props) {

    function openModal() {
        swal(<div>
            <p style={{ fontSize: "20px", marginTop: "20px" }}>
                <b>Bizi Değerlendirin</b>
            </p>
            <div className="row">
                <div className="col-md-12">
                    <hr></hr>
                </div>
            </div>
            <div className="rating" >
                <input type="radio" name="ratingValueOfQuality" value="5" id="5" /><label htmlFor="5">☆</label>
                <input type="radio" name="ratingValueOfQuality" value="4" id="4" /><label htmlFor="4">☆</label>
                <input type="radio" name="ratingValueOfQuality" value="3" id="3" /><label htmlFor="3">☆</label>
                <input type="radio" name="ratingValueOfQuality" value="2" id="2" /><label htmlFor="2">☆</label>
                <input type="radio" name="ratingValueOfQuality" value="1" id="1" /><label htmlFor="1">☆</label>
            </div>
            <p>KALİTE</p>
            <div className="rating" >
                <input type="radio" name="ratingValueOfPerformance" value="5" id="5_1" /><label htmlFor="5_1">☆</label>
                <input type="radio" name="ratingValueOfPerformance" value="4" id="4_1" /><label htmlFor="4_1">☆</label>
                <input type="radio" name="ratingValueOfPerformance" value="3" id="3_1" /><label htmlFor="3_1">☆</label>
                <input type="radio" name="ratingValueOfPerformance" value="2" id="2_1" /><label htmlFor="2_1">☆</label>
                <input type="radio" name="ratingValueOfPerformance" value="1" id="1_1" /><label htmlFor="1_1">☆</label>
            </div>
            <p>PERFORMANS</p>
            <div className="rating" >
                <input type="radio" name="ratingValueOfTiming" value="5" id="5_2" /><label htmlFor="5_2">☆</label>
                <input type="radio" name="ratingValueOfTiming" value="4" id="4_2" /><label htmlFor="4_2">☆</label>
                <input type="radio" name="ratingValueOfTiming" value="3" id="3_2" /><label htmlFor="3_2">☆</label>
                <input type="radio" name="ratingValueOfTiming" value="2" id="2_2" /><label htmlFor="2_2">☆</label>
                <input type="radio" name="ratingValueOfTiming" value="1" id="1_2" /><label htmlFor="1_2">☆</label>
            </div>
            <p>ZAMANLAMA</p>
            <div className="form-group">
                <hr></hr>
                <p>Görüşlerinizi bir kaç cümle ile belirtebilirsiniz</p>
                <textarea className="form-control" id="message-text" maxLength="200" style={{ height: "150px" }}></textarea>
            </div>
        </div>, { button: "Gönder" }
        ).then((messageIsSend) => {
            if (messageIsSend) {
                SendMessage();
            } else {
                // setIsOpen(false);
                document.getElementsByClassName('rainbow')[0].style.animation = "hueRotation 2s linear infinite";
            }
        });

    }
    function SendMessage() {
        if (document.querySelector('input[name="ratingValueOfQuality"]:checked') === null ||
            document.querySelector('input[name="ratingValueOfPerformance"]:checked') === null ||
            document.querySelector('input[name="ratingValueOfTiming"]:checked') === null) {
            swal("Tüm kriterleri puanlamanız gerekmektedir.", {
                icon: "warning",
                button: false
            });
            document.getElementsByClassName('rainbow')[0].style.animation = "hueRotation 2s linear infinite";
        } else {
            ratingValueOfQuality = document.querySelector('input[name="ratingValueOfQuality"]:checked').value;
            ratingValueOfPerformance = document.querySelector('input[name="ratingValueOfPerformance"]:checked').value;
            ratingValueOfTiming = document.querySelector('input[name="ratingValueOfTiming"]:checked').value;
            message = document.getElementById('message-text').value;
            document.getElementsByClassName('rainbow')[0].style.animation = "";
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_URL}/InsertDealerFeedBack`,
                data: {
                    routeId: props.data.routeId,
                    dealerId: props.data.dealerId,
                    RatingValueOfQuality: parseInt(ratingValueOfQuality),
                    RatingValueOfPerformance: parseInt(ratingValueOfPerformance),
                    RatingValueOfTiming: parseInt(ratingValueOfTiming),
                    CommentValue: message
                }
            }).then(response => {

                if (response.data.StatusCode === "200") {
                    swal("İşleminiz başarılı! Teşekkür ederiz.", {
                        icon: "success",
                        button: false
                    });
                    document.getElementsByClassName('rainbow')[0].style.animation = "";
                } else {
                    swal("İşlem başarısız!", {
                        button: false,
                    });
                }

            }).catch((err) => {
                swal(err.toString(), {
                    button: false,
                });
            });
        }
    }

    return <div className='mt-4'>
        <center><button className="rainbow noselect" onClick={openModal}>Bizi Değerlendirin</button></center>
    </div>;
}