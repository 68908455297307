import React, { useState } from 'react'
import { InfoWindow, withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import truckIcon from '../images/truck.png'
import Panel from '../components/Panel'
import MapLoading from '../components/MapLoading'

export default function Map(props) {
    const [dealerInfo, setDealerInfo] = useState(true);
    const [truckInfo, setTruckInfo] = useState(true);

    const MyMapComponent = withScriptjs(withGoogleMap(() =>
        <GoogleMap
            defaultZoom={14}
            defaultCenter={{ lat: props.data.latitude, lng: props.data.longitude }}>

            <Marker position={{ lat: props.data.dealerCoordinatLat, lng: props.data.dealerCoordinatLng }} onClick={() => { setDealerInfo(true) }}>
                {dealerInfo &&
                    <InfoWindow onCloseClick={() => { setDealerInfo(false) }}>
                        <div className="p-2">
                            <h5>Teslimat Adresi</h5>
                            <strong>Bayi : </strong>{props.data.dealerName} <br /><br />
                            {props.data.dealerAddress}
                        </div>
                    </InfoWindow>}
            </Marker>

            <Marker position={{ lat: props.data.latitude, lng: props.data.longitude }} onClick={() => { setTruckInfo(true) }} icon={truckIcon} >
                {truckInfo &&
                    <InfoWindow onCloseClick={() => { setTruckInfo(false) }}>
                        <div className="p-2">
                            <h5>Araç Konumu</h5>
                            {props.data.locationAddress}<br /><br />
                            <strong>Plaka : </strong>{props.data.vehicleName}
                        </div>
                    </InfoWindow>}
            </Marker>

        </GoogleMap>
    ))

    return (
        <div className="container">

            {/* {props.data.isGpsFixed === 1 && props.data.gpsDateStatus === 1 ? */}
            {props.data.isGpsFixed === 1 ?
                < MyMapComponent
                    isMarkerShown
                    googleMapURL={process.env.REACT_APP_GOOGLE_MAP_URL}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `600px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                /> :
                <MapLoading />
            }
            <Panel data={props.data} />
            <hr className="mt-4" size="4" />
        </div>
    )
}
