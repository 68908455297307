import React from 'react'

export default function MapLoading() {
  return (
    <div style={{
      backgroundColor: "#f1ede7",
      height: "250px",
      width: "100%"
    }}>
      <div style={{ textAlign: "center", padding: "50px", color: "#4d5887" }}>
        <div className="fa-3x">
          <i className="fas fa-spinner fa-pulse"></i>
        </div>
        <h5 style={{ margin: "10px" }}> Araç konum bilgisi belirleniyor.. </h5>
        <h6 style={{ margin: "10px" }}> Harita en kısa zamanda yüklenecektir. </h6>
      </div>
    </div>
  )
}
