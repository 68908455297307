import React from 'react'
import '../styles/Error.css'
import swal from 'sweetalert';

export default function Error(props) {

    if (props.data === "Err110") {
        swal({
            title: "Sayfa Bulunamadı",
            text: "Girmeye çalıştığınız linke ait sayfa bulunamadı.",
            icon: "error",
            button: "Tamam",
        });
    }

    if (props.data === "Err111") {
        swal({
            title: "Süreniz Doldu",
            text: "Sevkiyat takip sayfasının görüntülenme süresi doldu.",
            icon: "success",
            button: "Tamam",
        });
    }

    if (props.data === "Err112") {
        swal({
            title: "Kayıt Bulunamadı",
            text: "Sayfada görüntülenecek herhangi bir bilgiye rastlanmadı.",
            icon: "warning",
            button: "Tamam",
        });
    }

    return (
        <div className="loop-wrapper">
            <div className="mountain"></div>
            <div className="hill"></div>
            <div className="tree"></div>
            <div className="tree"></div>
            <div className="tree"></div>
            <div className="rock"></div>
            <div className="truck"></div>
            <div className="wheels"></div>
        </div>
    )
}
