import React from 'react'

export default function Footer() {
    return (
        <footer className="text-center text-white" style={{ backgroundColor: "#f1f1f1" }}>
            <div className="container p-4 pb-0">
                <section className="mb-4">
                    <a className="btn btn-outline-secondary rounded-circle m-1" href="https://www.facebook.com/sadelabs/" target="_blank" rel="noreferrer" role="button">
                        <i className="fab fa-facebook-f"></i>
                    </a>

                    <a className="btn btn-outline-secondary rounded-circle m-1" href="https://twitter.com/sadelabs" target="_blank" rel="noreferrer" role="button">
                        <i className="fab fa-twitter"></i>
                    </a>

                    <a className="btn btn-outline-secondary rounded-circle m-1" href="https://www.youtube.com/sadelabs" target="_blank" rel="noreferrer" role="button">
                        <i className="fab fa-youtube"></i>
                    </a>

                    <a className="btn btn-outline-secondary rounded-circle m-1" href="https://www.instagram.com/sadelabs_/" target="_blank" rel="noreferrer" role="button">
                        <i className="fab fa-instagram"></i>
                    </a>

                    <a className="btn btn-outline-secondary rounded-circle m-1" href="https://www.linkedin.com/company/sadelabs/" target="_blank" rel="noreferrer" role="button">
                        <i className="fab fa-linkedin-in"></i>
                    </a>
                </section>
            </div>

            <div className="text-center text-secondary p-3" style={{ backgroundColor: "#ccc" }}>
                Copyright © 2022 <a href="https://sadelabs.com/" target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "#424242" }}>Sadelabs </a>
                | All Rights Reserved.
            </div>
        </footer>
    )
}
